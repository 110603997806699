import logo from './logo.svg';
import './App.css';
import Htr_spedition from './Components/htr_spedition/htr_spedition';
function App() {
  return (

    <Htr_spedition name='test' />
  );
}

export default App;
