import React, { useState, Component } from "react";
import moment from "moment";
import axios from "axios";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";
import "./htr_spedition.css";
import logohtr from "../../Images/logos/logohtr.png";
import FullLittleCircle_url, {
  ReactComponent as FullLittleCircle,
} from "../../Images/logos/FullLittleCircle.svg";
//import logohegelmann_url,{ReactComponent as logohegelmann} from '../../Images/logos/logo.svg';
//import logohegelmann from '../../Images/logos/logo.svg';
import logohtfs from "../../Images/logos/logo.jpeg";
import logohegelmann from "../../Images/logos/logoHegelmann.png";
import logohttrucksparts from "../../Images/logos/logo.png";
import logoexpressheroes from "../../Images/logos/LogoExpressHeroes.png";
import logoiventra from "../../Images/logos/logoiventra.png";
import hjkhjk from "../../Images/hjkhjk.png";
import insta from "../../Images/insta.png";
import linkedin from "../../Images/linkedin.png";
import twitter from "../../Images/twitter.png";
import StartSVG from "../../Images/star-regular.svg";
import StartFullSVG from "../../Images/star-solid.svg";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { spfi, SPBrowser } from "@pnp/sp";
import "@pnp/sp/webs";
import "@pnp/sp/site-users/web";
import { validate } from 'email-validator';
import { Col, Row, Form } from "react-bootstrap";
import settings from "../../settings.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function PopUpWindow(props) {
  return (
    <Popup open={props.isOpen} position="right center">
      <div id="formsuccess">THANK YOU FOR YOUR MESSAGE. IT HAS BEEN SENT.</div>
    </Popup>
  );
}
function NotSuccessfull(props) {
  return (
    <Popup open={props.isInCorrectUrl.value} position="right center" onClose={props.callBack}>
      <div id="formsuccess">{props.isInCorrectUrl.message}</div>
    </Popup>
  );
}
interface IHtr_spedition {
  name: string;
}
class Htr_spedition extends Component<IHtr_speditionProps, IHtr_spedition> {
  sp;
  constructor(props: IHtr_speditionProps) {
    super(props);
    this.sp = spfi().using(
      SPBrowser({ baseUrl: "https://hegelmann.sharepoint.com/sites/RateMe" })
    );
    this.state = {
      name: props.name == undefined ? "" : props.name,
      isCaptcha: false,
      isInCorrectUrl: { value: false, message: "" },
      captchaValue: "",
      comments: "",
      recipient: "",
      //recipient:"test@hegelmann.com",
      sender: "",
      /*rate:"Bad",
            rate:"So-so",*/
      rate: "Great",
      category: "",
      type: "Employee",
      //type:"Company",
      date: moment().format("YYYY-DD-MM"),
      msgId: "",
      qos: "",
      slt: "",
      empComm: "",
      srvCost: "",
      recommend: "",
      brate: "",
      msgBody: "",
      uniqId: "",
      linkMessage: " You can also rate our company",
      isMessageSent: false,
      //companyName:"htr-spedition",
      //companyName:"httrucksparts",
      //companyName:"hegelmann",
      //companyName:"htfs",
      companyName: "expressheroes",
      //companyName:"iventra",
      logo: null,
      recipientArr: [],
      sign: "",
    };
  }

  onChangeReCAPTCHA = (value) => {
    console.log("value", value);
    if (value) {
      this.setState({ isCaptcha: true });
      this.setState({ captchaValue: value });
    } else {
      this.setState({ isCaptcha: false });
      this.setState({ captchaValue: "" });
    }
  };
  uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };
  handleCommentsChange = (event) => {
    this.setState({ comments: event.target.value });
  };
  handleRateChange = (event) => {
    this.setState({ rate: event.target.value });
  };
  handleCategoryChange = async (event) => {
    await this.setState({ category: event.target.value });
    console.log("this.state.category", this.state.category)
  }
  handleQosChange = (event) => {
    this.setState({ qos: event.target.value });
  };
  handleSltChange = (event) => {
    this.setState({ slt: event.target.value });
  };
  handleSrvCostChange = (event) => {
    this.setState({ srvCost: event.target.value });
  };
  handleEmpCommChange = (event) => {
    this.setState({ empComm: event.target.value });
  };
  handleRecommendChange = (event) => {
    this.setState({ recommend: event.target.value });
  };
  onChangeRecipient = (event) => {
    this.setState({ recipient: event.target.value });
  };
  handleChangeCompany = (event) => {
    event.preventDefault();
    if (this.state.type === "Employee") {
      this.setState({ type: "Company" });
      this.setState({ linkMessage: " You can also rate employee" });
    } else {
      this.setState({ type: "Employee" });
      this.setState({ linkMessage: " You can also rate our company" });
    }
  };
  isCommentRequired = () => this.state.category == "Other";
  categoryDisplaying = () => this.state.uniqId == "expressheroes";
  setError = async (message) => {
    await this.setState({ isInCorrectUrl: { value: true, message: message } });
    console.log("this.state.isCorrectUrl", this.state.isInCorrectUrl);
  }
  handleSubmit = async (event) => {
    console.log("click");
    if (!validate(this.state.sender)) {
      await this.setError("Error: Recipient address is invalid or empty");
    }
    if (!validate(this.state.recipient)) {
      await this.setError("Error: Selected email address is invalid or empty");
    }
    if (this.state.uniqId == "expressheroes" &&
      this.state.type == "Employee" &&
      this.state.category == "") {
      await this.setError("Error: You need to select category");
    }
    if (this.state.category == "Other" && this.state.comments == "") {
      await this.setError("Error: You need to leave a comment");
    }
    if (await this.checkServiceDate() && this.state.isCaptcha && !this.state.isInCorrectUrl.value) {
      console.log(this.state.isCaptcha);
      event.preventDefault();
      const headers = {
        "Content-Type": "application/json",
        authToken: settings.reteMeApiKey,
      };
      let msgBody = `${this.state.recipient}|${this.state.type}|${this.state.rate}|${this.state.qos}|${this.state.slt}|${this.state.empComm}|${this.state.srvCost}|${this.state.recommend}|${this.state.brate}|${this.state.comments}|${this.state.date}|${this.state.subject}|${this.state.recipient}|${this.state.msgId}|${this.state.uniqId}`;
      this.setState({ msgBody, msgBody });
      const rateMeClass = {
        Title: this.state.sender,
        Type: this.state.type,
        Rate: this.state.rate,
        Category: this.state.category,
        Comments: this.state.comments,
        Date: this.state.date,
        Subject: this.state.subject,
        Recipient: this.state.recipient,
        MsgId: this.state.msgId,
        Qos: this.state.qos,
        Slt: this.state.slt,
        EmpComm: this.state.empComm,
        SrvCost: this.state.srvCost,
        Recommend: this.state.recommend,
        Brate: this.state.brate,
        MsgBody: msgBody,
        UniqId: this.state.uniqId,
        Sign: this.state.sign,
      };
      console.log("recipient", this.state.recipient);
      console.log("msgBody", msgBody);
      console.log("rateMeClass", rateMeClass);
      axios
        .post(settings.rateMeApi, rateMeClass, {
          headers: headers,
        })
        .then((res) => {
          console.log(res.data);
        });
      this.setState({ isMessageSent: true });
    }
  };
  setLogo(_companyName) {
    switch (_companyName) {
      case "htr-spedition":
        this.setState({ logo: logohtr });
        break;
      case "httrucksparts":
        this.setState({ logo: logohttrucksparts });
        break;
      case "hegelmann":
        this.setState({ logo: logohegelmann });
        break;
      case "expressheroes":
        this.setState({ logo: logoexpressheroes });
        break;
      case "ivetra":
        this.setState({ logo: logoiventra });
        break;
      default:
        this.setState({ logo: logohtfs });
        break;
    }
  }
  setUniqId(_companyName) {
    switch (_companyName) {
      case "htr-spedition":
        this.setState({ uniqId: "htr_spedition" });
        break;
      case "TransAlbert":
        this.setState({ uniqId: "TransAlbert" });
        break;
      case "httrucksparts":
        this.setState({ uniqId: "httrucksparts" });
        break;
      case "hegelmann":
        this.setState({ uniqId: "hegelmann" });
        break;
      case "expressheroes":
        this.setState({ uniqId: "expressheroes" });
        break;
      case "ivetra":
        this.setState({ uniqId: "ivetra" });
        break;
      default:
        this.setState({ uniqId: "htfs" });
        break;
    }
  }
  setRightUniqId(UniqID) {
    this.setState({ uniqId: UniqID });
  }
  componentDidMount() {
    this.setState({ msgId: this.uuidv4() });
    this.setState({ uniqId: this.uuidv4() });
    this.readParams();
  }
  readParams = async () => {
    let path = window.location.href.toLowerCase().split("?")[0];
    let _companyName = this.state.companyName;
    if (path.indexOf("htr-spedition") > -1) {
      _companyName = "htr-spedition";
    } else if (path.indexOf("hegelmann") > -1) {
      _companyName = "hegelmann";
    } else if (path.indexOf("httrucksparts") > -1) {
      _companyName = "httrucksparts";
    } else if (path.indexOf("htfs") > -1) {
      _companyName = "htfs";
    } else if (path.indexOf("expressheroes") > -1) {
      _companyName = "expressheroes";
    } else if (path.indexOf("ivetra") > -1) {
      _companyName = "ivetra";
    }
    this.setState({ companyName: _companyName });
    this.setLogo(_companyName);
    this.setUniqId(_companyName);
    if (this.gup("rate")) {
      this.setState({ rate: this.gup("rate") });
    }
    if (this.gup("type")) {
      this.setState({ type: this.gup("type") });
    }
    if (this.gup("subject")) {
      this.setState({ subject: this.gup("subject") });
    }
    if (this.gup("date")) {
      await this.setState({ date: this.gup("date") });
      //await this.checkServiceDate();
    }
    if (this.gup("recipient")) {
      let recipient = this.gup("recipient");
      let recipientArray = recipient.split(";");

      // if (_companyName == "hegelmann") {
      //   recipientArray.map((recipient, ind) => {
      //     if (recipient.indexOf("hegelmann") > -1)
      //       recipientArray.splice(ind, ind + 1);
      //   });
      // }
      await this.setState({
        recipient: recipientArray[0],
        recipientArr: recipientArray,
      });
    }
    if (this.gup("sender")) {
      this.setState({ sender: this.gup("sender") });
    }
    if (this.gup("msgId")) {
      this.setState({ msgId: this.gup("msgId") });
    }
    if (this.gup("uniqId") == "TransAlbert") {
      let UniqId = this.gup("uniqId");
      this.setUniqId(UniqId);
      console.log(this.state.uniqId);
    }
  };
  checkServiceDate = async () => {
    var todayStr = moment().format("MM/DD/YYYY");
    var todayDate = new Date(todayStr);

    var serviceDateStr = this.state.date.split(' ')[0].split('.');
    var day = serviceDateStr[0];
    var month = serviceDateStr[1];
    var year = serviceDateStr[2];
    serviceDateStr = `${month}/${day}/${year}`;
    var serviceDate = new Date(serviceDateStr);

    const diffTime = Math.abs(todayDate - serviceDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    console.log(diffDays);
    if (diffDays > 14) {
      await this.setError("Evaluation time is over. Evaluation isn't available");
      return false; 
    }
    return true;
  }
  gup = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(url);
    return results == null ? null : decodeURIComponent(results[1]);
  };

  componentWillReceiveProps(nextProps: IHtr_spedition) {
    this.setState({ name: nextProps.name == undefined ? "" : nextProps.name });
  }
 render() {
    // console.log("isCaptcha", this.state.isCaptcha);
    // console.log("captchaValue", this.state.captchaValue);
    console.log("this.state.", this.state.recipientArr);
    return (
      <>
        <header
          className={
            this.state.companyName == "htr-spedition"
              ? ""
              : this.state.companyName == "expressheroes"
                ? "green"
                : "blue"
          }
        >
          <Row className="main-header">
            <div>
              <img
                style={{
                  maxWidth: "210px",
                  display: "block",
                  margin: "0 auto",
                }}
                src={this.state.logo}
                alt="Logo"
              />
            </div>
          </Row>
        </header>
        <div className="career-container">
          <div className="container feedback-form">
            <Row>
              <Col sm="3"></Col>
              <Col
                sm="6"
                style={{ display: this.state.isMessageSent ? "none" : "" }}
                className="companyform"
              >
                <h1
                  className={
                    this.state.companyName == "htr-spedition"
                      ? ""
                      : this.state.companyName == "expressheroes"
                        ? "green"
                        : "blue"
                  }
                >
                  Thank you for your response!
                </h1>
                <div
                  id="EmployeeDiv"
                  style={{
                    display: this.state.type === "Employee" ? "" : "none",
                  }}
                >
                  <h2>Employees Rating</h2>
                  <div className="fielditem">
                    <label>Choose your email</label>

                    <span className="wpcf7-form-control-wrap recipients">
                      <select
                        placeholder="-- Choose your email --"
                        name="recipients"
                        onChange={this.onChangeRecipient}
                        className="wpcf7-form-control wpcf7-select recipients"
                        id="recipients"
                        aria-invalid="false"
                      >
                        {this.state.recipientArr.map((i) => {
                          return (
                            <option key={i} value={i}>
                              {i}
                            </option>
                          );
                        })}
                      </select>
                    </span>
                  </div>

                  <div className="fielditem">
                    <label>Rate</label>
                    <span className="wpcf7-form-control-wrap enquiry-rate">
                      <span
                        className="wpcf7-form-control wpcf7-radio rate"
                        id="rate"
                      >
                        <span className="wpcf7-list-item first">
                          <input
                            type="radio"
                            name="enquiry-rate"
                            value="Bad"
                            checked={this.state.rate === "Bad"}
                            onChange={this.handleRateChange}
                          />
                          <span className="wpcf7-list-item-label">Bad</span>
                        </span>
                        <span className="wpcf7-list-item">
                          <input
                            type="radio"
                            name="enquiry-rate"
                            value="So-so"
                            checked={this.state.rate === "So-so"}
                            onChange={this.handleRateChange}
                          />
                          <span className="wpcf7-list-item-label">So-so</span>
                        </span>
                        <span className="wpcf7-list-item last">
                          <input
                            type="radio"
                            name="enquiry-rate"
                            value="Great"
                            checked={this.state.rate === "Great"}
                            onChange={this.handleRateChange}
                          />
                          <span className="wpcf7-list-item-label">Great</span>
                        </span>
                      </span>
                    </span>
                    <span className="wpcf7-form-control-wrap hiddendefault">
                      <input
                        type="hidden"
                        name="hiddendefault"
                        value=""
                        size="40"
                        className="wpcf7-form-control wpcf7dtx-dynamictext wpcf7-dynamichidden"
                        aria-invalid="false"
                      />
                    </span>
                  </div>
                  {this.categoryDisplaying() &&
                    <div className="fielditem">
                      <label className="categoryLabel">Select category<span>*</span></label>
                      <span className="categorList">
                        <span className="categoryCheckbox">
                          <input
                            type="checkbox"
                            value="Service quality"
                            checked={this.state.category === "Service quality"}
                            onChange={this.handleCategoryChange} />
                          <span>Service quality</span>
                        </span>
                        <span className="categoryCheckbox">
                          <input
                            type="checkbox"
                            value="Service price"
                            checked={this.state.category === "Service price"}
                            onChange={this.handleCategoryChange} />
                          <span>Service price</span>
                        </span>
                        <span className="categoryCheckbox">
                          <input
                            type="checkbox"
                            value="Communication/professionalism"
                            checked={this.state.category === "Communication/professionalism"}
                            onChange={this.handleCategoryChange} />
                          <span>Communication/professionalism</span>
                        </span>
                        <span className="categoryCheckbox">
                          <input
                            type="checkbox"
                            value="Other"
                            checked={this.state.category === "Other"}
                            onChange={this.handleCategoryChange} />
                          <span>Other</span>
                        </span>
                      </span>
                      <span className="wpcf7-form-control-wrap hiddendefault">
                        <input
                          type="hidden"
                          name="hiddendefault"
                          value=""
                          size="40"
                          className="wpcf7-form-control wpcf7dtx-dynamictext wpcf7-dynamichidden"
                          aria-invalid="false"
                        />
                      </span>
                    </div>
                  }
                </div>
                <div
                  id="CompanyDiv"
                  style={{
                    display: this.state.type === "Company" ? "" : "none",
                  }}
                >
                  <Col sm="6">
                    <h1>Company Rating</h1>
                    <div className="staritem">
                      <label>Quality of Service:</label>
                      <span
                        id="qualityofservice"
                        className="wpcf7-form-control wpcf7-starratingawesome qualityofservice starratingawesome"
                        data-def="0"
                      >
                        <input
                          id="starqualityofservice_5"
                          type="radio"
                          name="qualityofservice"
                          value="5"
                          onChange={this.handleQosChange}
                        ></input>
                        <label htmlFor="starqualityofservice_5" title="5">
                          <img
                            src={this.state.qos < 5 ? StartSVG : StartFullSVG}
                          />
                        </label>
                        <input
                          id="starqualityofservice_4"
                          type="radio"
                          name="qualityofservice"
                          value="4"
                          onChange={this.handleQosChange}
                        ></input>
                        <label htmlFor="starqualityofservice_4" title="4">
                          <img
                            src={this.state.qos < 4 ? StartSVG : StartFullSVG}
                          />
                        </label>
                        <input
                          id="starqualityofservice_3"
                          type="radio"
                          name="qualityofservice"
                          value="3"
                          onChange={this.handleQosChange}
                        ></input>
                        <label htmlFor="starqualityofservice_3" title="3">
                          <img
                            src={this.state.qos < 3 ? StartSVG : StartFullSVG}
                          />
                        </label>
                        <input
                          id="starqualityofservice_2"
                          type="radio"
                          name="qualityofservice"
                          value="2"
                          onChange={this.handleQosChange}
                        ></input>
                        <label htmlFor="starqualityofservice_2" title="2">
                          <img
                            src={this.state.qos < 2 ? StartSVG : StartFullSVG}
                          />
                        </label>
                        <input
                          id="starqualityofservice_1"
                          type="radio"
                          name="qualityofservice"
                          value="1"
                          onChange={this.handleQosChange}
                        ></input>
                        <label htmlFor="starqualityofservice_1" title="1">
                          <img
                            src={this.state.qos < 1 ? StartSVG : StartFullSVG}
                          />
                        </label>
                      </span>
                    </div>
                    <div className="staritem">
                      <label>Service Lead Time:</label>
                      <span
                        id="serviceleadtime"
                        className="wpcf7-form-control wpcf7-starratingawesome serviceleadtime starratingawesome"
                        data-def="0"
                      >
                        <input
                          id="starserviceleadtime_5"
                          type="radio"
                          name="serviceleadtime"
                          onChange={this.handleSltChange}
                          value="5"
                          checked={this.state.slt === "5"}
                        ></input>
                        <label htmlFor="starserviceleadtime_5" title="5">
                          <img
                            src={this.state.slt < 5 ? StartSVG : StartFullSVG}
                          />
                        </label>
                        <input
                          id="starserviceleadtime_4"
                          type="radio"
                          name="serviceleadtime"
                          onChange={this.handleSltChange}
                          value="4"
                          checked={this.state.slt === "4"}
                        ></input>
                        <label htmlFor="starserviceleadtime_4" title="4">
                          <img
                            src={this.state.slt < 4 ? StartSVG : StartFullSVG}
                          />
                        </label>
                        <input
                          id="starserviceleadtime_3"
                          type="radio"
                          name="serviceleadtime"
                          onChange={this.handleSltChange}
                          value="3"
                          checked={this.state.slt === "3"}
                        ></input>
                        <label htmlFor="starserviceleadtime_3" title="3">
                          <img
                            src={this.state.slt < 3 ? StartSVG : StartFullSVG}
                          />
                        </label>
                        <input
                          id="starserviceleadtime_2"
                          type="radio"
                          name="serviceleadtime"
                          onChange={this.handleSltChange}
                          value="2"
                          checked={this.state.slt === "2"}
                        ></input>
                        <label htmlFor="starserviceleadtime_2" title="2">
                          <img
                            src={this.state.slt < 2 ? StartSVG : StartFullSVG}
                          />
                        </label>
                        <input
                          id="starserviceleadtime_1"
                          type="radio"
                          name="serviceleadtime"
                          onChange={this.handleSltChange}
                          value="1"
                          checked={this.state.slt === "1"}
                        ></input>
                        <label htmlFor="starserviceleadtime_1" title="1">
                          <img
                            src={this.state.slt < 1 ? StartSVG : StartFullSVG}
                          />
                        </label>
                      </span>
                    </div>
                    <div className="staritem">
                      <label>Employee Communication:</label>
                      <span
                        id="employeecommunication"
                        className="wpcf7-form-control wpcf7-starratingawesome employeecommunication starratingawesome"
                        data-def="0"
                      >
                        <input
                          id="staremployeecommunication_5"
                          type="radio"
                          onChange={this.handleEmpCommChange}
                          name="employeecommunication"
                          checked={this.state.empComm === "5"}
                          value="5"
                        ></input>
                        <label htmlFor="staremployeecommunication_5" title="5">
                          <img
                            src={
                              this.state.empComm < 5 ? StartSVG : StartFullSVG
                            }
                          />
                        </label>
                        <input
                          id="staremployeecommunication_4"
                          type="radio"
                          onChange={this.handleEmpCommChange}
                          name="employeecommunication"
                          checked={this.state.empComm === "4"}
                          value="4"
                        ></input>
                        <label htmlFor="staremployeecommunication_4" title="4">
                          <img
                            src={
                              this.state.empComm < 4 ? StartSVG : StartFullSVG
                            }
                          />
                        </label>
                        <input
                          id="staremployeecommunication_3"
                          type="radio"
                          onChange={this.handleEmpCommChange}
                          name="employeecommunication"
                          checked={this.state.empComm === "3"}
                          value="3"
                        ></input>
                        <label htmlFor="staremployeecommunication_3" title="3">
                          <img
                            src={
                              this.state.empComm < 3 ? StartSVG : StartFullSVG
                            }
                          />
                        </label>
                        <input
                          id="staremployeecommunication_2"
                          type="radio"
                          onChange={this.handleEmpCommChange}
                          name="employeecommunication"
                          checked={this.state.empComm === "2"}
                          value="2"
                        ></input>
                        <label htmlFor="staremployeecommunication_2" title="2">
                          <img
                            src={
                              this.state.empComm < 2 ? StartSVG : StartFullSVG
                            }
                          />
                        </label>
                        <input
                          id="staremployeecommunication_1"
                          type="radio"
                          onChange={this.handleEmpCommChange}
                          name="employeecommunication"
                          checked={this.state.empComm === "1"}
                          value="1"
                        ></input>
                        <label htmlFor="staremployeecommunication_1" title="1">
                          <img
                            src={
                              this.state.empComm < 1 ? StartSVG : StartFullSVG
                            }
                          />
                        </label>
                      </span>
                    </div>
                    <div className="staritem">
                      <label>Service Cost:</label>
                      <span
                        id="servicecost"
                        className="wpcf7-form-control wpcf7-starratingawesome servicecost starratingawesome"
                        data-def="0"
                      >
                        <input
                          id="starservicecost_5"
                          type="radio"
                          name="servicecost"
                          onChange={this.handleSrvCostChange}
                          checked={this.state.srvCost === "5"}
                          value="5"
                        ></input>
                        <label htmlFor="starservicecost_5" title="5">
                          <img
                            src={
                              this.state.srvCost < 5 ? StartSVG : StartFullSVG
                            }
                          />
                        </label>
                        <input
                          id="starservicecost_4"
                          type="radio"
                          name="servicecost"
                          onChange={this.handleSrvCostChange}
                          checked={this.state.srvCost === "4"}
                          value="4"
                        ></input>
                        <label htmlFor="starservicecost_4" title="4">
                          <img
                            src={
                              this.state.srvCost < 4 ? StartSVG : StartFullSVG
                            }
                          />
                        </label>
                        <input
                          id="starservicecost_3"
                          type="radio"
                          name="servicecost"
                          onChange={this.handleSrvCostChange}
                          checked={this.state.srvCost === "3"}
                          value="3"
                        ></input>
                        <label htmlFor="starservicecost_3" title="3">
                          <img
                            src={
                              this.state.srvCost < 3 ? StartSVG : StartFullSVG
                            }
                          />
                        </label>
                        <input
                          id="starservicecost_2"
                          type="radio"
                          name="servicecost"
                          onChange={this.handleSrvCostChange}
                          checked={this.state.srvCost === "2"}
                          value="2"
                        ></input>
                        <label htmlFor="starservicecost_2" title="2">
                          <img
                            src={
                              this.state.srvCost < 2 ? StartSVG : StartFullSVG
                            }
                          />
                        </label>
                        <input
                          id="starservicecost_1"
                          type="radio"
                          name="servicecost"
                          onChange={this.handleSrvCostChange}
                          checked={this.state.srvCost === "1"}
                          value="1"
                        ></input>
                        <label htmlFor="starservicecost_1" title="1">
                          <img
                            src={
                              this.state.srvCost < 1 ? StartSVG : StartFullSVG
                            }
                          />
                        </label>
                      </span>
                    </div>
                    <div className="fielditem">
                      <label>Would you recommend us to your friends?</label>
                      <span className="wpcf7-form-control-wrap recommend">
                        <span
                          className="wpcf7-form-control wpcf7-radio recommend"
                          id="recommend"
                        >
                          <span className="wpcf7-list-item first">
                            <label>
                              <span className="wpcf7-list-item-label">Yes</span>
                              <input
                                type="radio"
                                onChange={this.handleRecommendChange}
                                name="recommend"
                                value="Yes"
                                checked={this.state.recommend === "Yes"}
                              ></input>
                            </label>
                          </span>
                          <span className="wpcf7-list-item last">
                            <label>
                              <span className="wpcf7-list-item-label">No</span>
                              <input
                                onChange={this.handleRecommendChange}
                                type="radio"
                                name="recommend"
                                value="No"
                                checked={this.state.recommend === "No"}
                              ></input>
                            </label>
                          </span>
                        </span>
                      </span>
                    </div>
                  </Col>
                </div>
                <div className="fielditem">
                  <label className="categoryLabel">{this.state.uniqId == "expressheroes" ? "Please share more details on your case. This will help us to act accordingly" : "Comment"} {this.isCommentRequired() && <span>*</span>}</label>
                  <span className="wpcf7-form-control-wrap comment">
                    <textarea
                      value={this.state.comments}
                      onChange={this.handleCommentsChange}
                      name="comment"
                      cols="40"
                      rows="10"
                      className="wpcf7-form-control wpcf7-textarea comment"
                      id="comment"
                      aria-invalid="false"
                      placeholder="Comment"
                    ></textarea>
                  </span>
                </div>
                <div className="recaptchaDiv">
                  <ReCAPTCHA
                    style={{
                      border: this.state.isCaptcha ? "" : "2px red solid",
                      width: "306px",
                    }}
                    onChange={this.onChangeReCAPTCHA}
                    sitekey={settings.reCaptchaKeyV2}
                  ></ReCAPTCHA>
                  {this.state.isCaptcha ? null : (
                    <span style={{ color: "red" }}>Let`s verify</span>
                  )}
                </div>
                <input
                  onClick={this.handleSubmit}
                  disabled={!this.state.isCaptcha}
                  type="button"
                  value="Send"
                  className="wpcf7-form-control wpcf7-submit send"
                  id="send"
                />

                <a
                  href="#"
                  onClick={this.handleChangeCompany}
                  className="linktoform"
                >
                  <i className="fa fa-angle-double-right"></i>
                  {this.state.linkMessage}
                </a>
              </Col>
              {this.state.isInCorrectUrl && (<NotSuccessfull isInCorrectUrl={this.state.isInCorrectUrl} callBack={() => this.setState({ isInCorrectUrl: { value: false, message: "" } })} />)}

              <PopUpWindow isOpen={this.state.isMessageSent} />
              <Col sm="3"></Col>
              {this.state.companyName == "expressheroes" ? (
                <></>
              ) : (
                <div className="socials">
                  <a href="https://twitter.com/HegelmannGroup">
                    <img src={twitter}></img>
                  </a>
                  <a href="https://www.linkedin.com/company/hegelmanngroup">
                    <img src={linkedin}></img>
                  </a>
                  <a href="https://www.instagram.com/hegelmann.deutschland/">
                    <img src={insta}></img>
                  </a>
                  <a href="https://www.facebook.com/HegelmannGroup/">
                    <img src={hjkhjk}></img>
                  </a>
                </div>
              )}
            </Row>
          </div>
        </div>
      </>
    );
  }
}
export default Htr_spedition;
